/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://6xkzpoaomnfizadh5jumhowffe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-y6bjbueexrga7m4s4lu2ydzraa",
    "aws_cognito_identity_pool_id": "us-east-1:93bc5c17-9788-41c2-9e97-fe924b774620",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Vg1WJWlWt",
    "aws_user_pools_web_client_id": "18pvieugtsffe5sfl5nq4hcbd1",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PREFERRED_USERNAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ilovefood2b5d952e01764dddb99b1a80e7a5d7fe225423-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
